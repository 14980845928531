import React, { Component } from 'react'
import ReactDOM from 'react-dom'

//#region Components

import Attendees from './MinuteEditorAttendees'
import EditorListItem from './MinuteEditorListItem'
import { EditorToolbar, Toolbar } from '../../shared/Toolbar'
import Footer from './MinuteEditorListViewFooter'
import AutoScroller from './MinuteEditorListAutoScroller'

import DomHelper from '../../helpers/DomHelper'
import { deleteAction } from 'businesslayer/api/actions'
import { getMinutesDetail } from 'businesslayer/api/minutes'
import { getSections } from 'businesslayer/api/sections'
import { updatePregenerationAIMinutes } from 'businesslayer/networkrequests'
//#endregion

//#region Redux

import { connect } from 'react-redux'
import { withRouter, RouteComponentProps, Redirect } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { actions } from '../../../reducers/minuteTakerReducer'
import { actions as actionEditorReducer } from 'reducers/actionEditorReducer'

import selectors from '../../../selectors/minuteTakerSelectors'
import styled from '@emotion/styled'
import { List, RootRef } from 'components/shared/StyledComponents'
import { ColorBaseWhite } from 'assets/styles/variables'
import { pathOr } from 'rambdax'
import ActionItemEditDialog from '../components/ActionItemEditDialog/ActionItemEditDialog'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { AddActionButton, AddSectionButton } from 'components/shared/Toolbar/ToolbarElements'
import { css } from '@emotion/css'
import { DocumentToolbar } from 'components/shared/Toolbar/ComposedToolbars/DocumentToolbar'
import { Box, Button, Dialog, Divider } from '@material-ui/core'
import i18n from 'common/util/internationalization'
import UtilityExpandCollapse from './UtilityExpandCollapse'
import ListSubheader from '@material-ui/core/ListSubheader'
import MinutesActions from '../components/MinutesActions'
import {
    EDITOR_TABS,
    DOCUMENT_STATUS,
    PREGENERATION_DOCUMENT_STATUS
} from '../components/InvitationView/types'
import LinearIndeterminate from 'components/shared/LinearProgress'
import { AIGenerationIcon } from 'assets/icons/AiGenerationIcon'
import { ClassNames } from '@emotion/react'
import ToastErrorIcon from 'assets/icons/ToastErrorIcon'
import { getLocalStorageItem } from 'businesslayer/minutesLocalStore'
import { getPreGenerationStatus } from 'businesslayer/networkrequests/preGenerationStatusService'
import { transientStorageManager } from 'businesslayer/minutesSessionStore'
import SuccessIcon from 'assets/icons/SuccessIcon'
import applicationRouter from 'businesslayer/applicationRouter'

//#endregion

//#region Styles

const headerHeight = 80

// const progressSize = 60
const progressContainerSize = 71
const toolbarSize = 56
const newToolbarSize = 80
const initialHeight = 640

const StyleWrapper = styled('div')<{ height: number }>`
    color: ${ColorBaseWhite};
    height: ${({ height }) => height}px;
`

const ListWrapper = styled('div')`
    background-color: ${ColorBaseWhite};
    min-width: 700px;
    max-width: 1090px;
    margin: 24px auto;
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.2);
`
const ToastContainer = styled('div')`
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    gap: 10px;
`
const HeaderText = styled('span')`
    font-size: 22px;
    font-weight: 600;
    padding-left: 5px;
`
const ProgressMessage = styled('p')`
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-weight: 400;
    padding: 20px;
    line-height: 20px;
`
const Link = styled('a')`
    color: #455d82;
    font-weight: 700;
    padding-top: 16px;
`

const ToolbarCss = css`
    display: flex;
    gap: 16px;
    align-items: center;
`
const ToolbarBoxCss = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border: 1px solid #e6e6e6;
`
const listSubheader = css`
    z-index: 96;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 15px;
`
const aiDialogContainer = css`
    padding: 10px 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    border: 3px solid transparent;
    background: linear-gradient(white, white) padding-box,
        linear-gradient(169.21deg, #d3222a 0.16%, #b646f6 46.64%, #4d1dd5 98.77%) border-box;
`

const aiDialogHeader = css`
    display: flex;
    align-items: center;
    height: 58px;
    padding: 16px;
`
const aiGenerateFooter = css`
    display: flex;
    gap: 10px;
    float: right;
    padding-right: 20px;
    padding-top: 10px;
    place-content: end;
`
const customButton = css`
    display: flex;
    align-items: center;
    text-transform: none;
    font-size: 16px;
    font-weight: 600;
    padding: 8px 12px;
`
const TitleLink = styled('a')<{ status: string }>`
    color: ${({ status }) =>
        status === PREGENERATION_DOCUMENT_STATUS.AUTOMATED ||
        status === PREGENERATION_DOCUMENT_STATUS.PROGRESS
            ? '#05704B'
            : '#AF292E'};
    font-weight: 600;
    padding-top: 16px;
    text-decoration: none;
`

//#endregion

//#region Props

type Props = {
    actions: {
        parseSectionData: (...args: any[]) => void
        addAction: () => void
        addSection: () => void
        loadSections: (...args: any[]) => void
        editAction: (...args: any[]) => void
        toggleExpandCollapseAll: (arg: boolean) => void
        removeAction: (...args: any[]) => void
        selectActionItem: (...args: any[]) => void
        // setGlobalFocusLockState: (...args: any[]) => void
        changeSectionOrder: (...args: any[]) => void
        loadActionsFulfilled: (...args: any[]) => void
        fetchMinuteItemFulfilled: (...args: any[]) => void
    }

    minutesSections: Array<any>
    currentMinuteItem: any
    selectedTabIndex: number
    actionItems: Array<any>
    isEditingAction: boolean
    currentSelectedTab: string
    onActionChecked: (...args: any[]) => void
    style: any
    setIsLoading: any
    onStatusChange: (status: string) => void
} & RouteComponentProps

//#endregion

//#region Implementation
//This is umprella component for sections.
//It also hosts tollbar with buttons and has some scroll management logic
class MinuteEditorListView extends React.PureComponent<Props> {
    private intervalId: ReturnType<typeof setTimeout> | null = null
    scrolledNewItems: Map<string, any> = new Map()
    allListNodes: Map<string, any> = new Map()

    lastListItem: Component | null = null
    list?: any
    isScrollingToLastItem: boolean = false
    state = {
        height: initialHeight,
        isExpanded: true,
        pregeneratedMinutes: null,
        navigatingOut: false,
        loaded: false,
        pregenerationStatus: PREGENERATION_DOCUMENT_STATUS.PROGRESS,
        minutesDocuments: [],
        isPolling: true,
        toasts: [],
        committeeIDs: []
    }

    async componentDidMount() {
        this.setState({ committeeIDs: JSON.parse(getLocalStorageItem('committeeIds')) })
        await this.getPregeneratedMinutes(null)
        this.setState({ loaded: true })
        if (this.state.isPolling === true) {
            this.startPolling()
        }
    }

    startPolling = () => {
        this.fetchMinutesDocuments()
        this.intervalId = setInterval(this.fetchMinutesDocuments, 20000)
    }

    stopPolling = () => {
        if (this.intervalId) {
            clearInterval(this.intervalId)
            this.intervalId = null
        }
    }

    componentWillUnmount() {
        this.stopPolling()
    }

    private removeToast = (id: string) => {
        this.setState({ toasts: this.state.toasts.filter((toast: any) => toast?.id !== id) })
    }

    private checkForPregenStatus = (pregenStatus: string) => {
        if (
            (pregenStatus === PREGENERATION_DOCUMENT_STATUS.AUTOMATED &&
                this.state.pregenerationStatus === PREGENERATION_DOCUMENT_STATUS.PROGRESS) ||
            (pregenStatus === PREGENERATION_DOCUMENT_STATUS.FAILED &&
                this.state.pregenerationStatus === PREGENERATION_DOCUMENT_STATUS.PROGRESS) ||
            (pregenStatus === PREGENERATION_DOCUMENT_STATUS.PARTIAL_SUCCESS &&
                this.state.pregenerationStatus === PREGENERATION_DOCUMENT_STATUS.PROGRESS) ||
            (pregenStatus === PREGENERATION_DOCUMENT_STATUS.UNAPPROVED_ERROR &&
                this.state.pregenerationStatus === PREGENERATION_DOCUMENT_STATUS.PROGRESS)
        ) {
            return true
        }
        return false
    }

    fetchMinutesDocuments = async () => {
        try {
            if (this.state.committeeIDs.length === 0) {
                const response = await getPreGenerationStatus([
                    this.props.currentMinuteItem?.relationships?.committee?.data?.id
                ])
                const booksData = response?.data
                const result = booksData.find(
                    (item: any) => item.id === this.props.currentMinuteItem?.id
                )
                const pregenStatus = result?.attributes?.pregenerated_minutes
                if (this.checkForPregenStatus(pregenStatus)) {
                    this.props.actions.loadSections(
                        await getSections(this.props.currentMinuteItem?.id)
                    )
                    await this.getPregeneratedMinutes(pregenStatus)
                    this.setState({ pregenerationStatus: pregenStatus })
                    this.setState({ pregeneratedMinutes: pregenStatus })
                    if (this.intervalId) {
                        clearInterval(this.intervalId)
                    }
                } else {
                    // Keep polling for 'progress' status
                    this.setState({ pregenerationStatus: pregenStatus })
                }
            } else {
                const response =
                    this.state.isPolling === true
                        ? await getPreGenerationStatus(this.state.committeeIDs)
                        : null
                const pregenDocumentsData = response?.data
                this.setState({ minutesDocuments: pregenDocumentsData })
                const hasProgress = pregenDocumentsData.some(
                    (item: any) =>
                        item.attributes.pregenerated_minutes ===
                        PREGENERATION_DOCUMENT_STATUS.PROGRESS
                )
                const result = pregenDocumentsData.find(
                    (item: any) => item.id === this.props.currentMinuteItem?.id
                )
                const pregenStatus = result?.attributes?.pregenerated_minutes
                if (this.checkForPregenStatus(pregenStatus)) {
                    if (
                        pregenStatus === PREGENERATION_DOCUMENT_STATUS.AUTOMATED ||
                        pregenStatus === PREGENERATION_DOCUMENT_STATUS.PARTIAL_SUCCESS
                    ) {
                        this.props.actions.loadSections(
                            await getSections(this.props.currentMinuteItem?.id)
                        )
                    }
                    await this.getPregeneratedMinutes(pregenStatus)
                    this.setState({ pregenerationStatus: pregenStatus })
                    this.setState({ pregeneratedMinutes: pregenStatus })
                }
                if (!hasProgress) {
                    this.setState({ isPolling: false })
                    // TODO: move to componentdidupdate
                    if (this.intervalId && this.state.isPolling === false) {
                        clearInterval(this.intervalId)
                        this.intervalId = null
                    }
                }
                // Filter pregenDocumentsData to show only non 'progress' toasts
                const newToasts = pregenDocumentsData
                    .filter(
                        ({ attributes }) =>
                            attributes.pregenerated_minutes !==
                            PREGENERATION_DOCUMENT_STATUS.PROGRESS
                    )
                    .map(({ attributes }) => ({
                        id: `${attributes.document_id}`,
                        MinutesTitle:
                            attributes.pregenerated_minutes ===
                            PREGENERATION_DOCUMENT_STATUS.UNAPPROVED_ERROR
                                ? i18n.t('AI_GENERATE_FAIL_TITLE')
                                : attributes.pregenerated_minutes ===
                                  PREGENERATION_DOCUMENT_STATUS.AUTOMATED
                                ? i18n.t('MEETING_MINUTES_READY')
                                : attributes.pregenerated_minutes ===
                                  PREGENERATION_DOCUMENT_STATUS.PARTIAL_SUCCESS
                                ? i18n.t('AI_GENERATE_PARTIAL_FAIL_TITLE')
                                : i18n.t('AI_GENERATE_FAIL_TITLE'),
                        author_id: `${attributes.author_id}`,
                        current_user_id: `${transientStorageManager?.currentUser?.id}`,
                        status: `${attributes.pregenerated_minutes}`,
                        view_toast: attributes.view_toast,
                        statusMessage:
                            attributes.pregenerated_minutes ===
                            PREGENERATION_DOCUMENT_STATUS.UNAPPROVED_ERROR
                                ? i18n.t('UNAPPROVED_STATUS', {
                                      minutesTitle: attributes.title
                                  })
                                : attributes.pregenerated_minutes ===
                                  PREGENERATION_DOCUMENT_STATUS.AUTOMATED
                                ? i18n.t('AI_GENERATE_SUCCESS_DESCRIPTION', {
                                      minutesTitle: attributes.title
                                  })
                                : attributes.pregenerated_minutes ===
                                  PREGENERATION_DOCUMENT_STATUS.PARTIAL_SUCCESS
                                ? i18n.t('AI_GENERATE_PARTIAL_FAIL_SUBTITLE', {
                                      minutesTitle: attributes.title
                                  })
                                : i18n.t('AI_GENERATE_FAIL_SUBTITLE', {
                                      minutesTitle: attributes.title
                                  })
                    }))
                this.setState({ toasts: newToasts })

                newToasts.forEach((toast) => {
                    if (toast.id !== this.props.currentMinuteItem?.id) {
                        setTimeout(() => {
                            this.setState((prevState: any) => ({
                                toasts: prevState.toasts.filter((t) => t.id !== toast.id)
                            }))
                            if (
                                toast?.id !== this.props.currentMinuteItem?.id &&
                                toast.view_toast === true
                            ) {
                                updatePregenerationAIMinutes(toast.id, toast.status, false)
                            } else if (
                                toast?.id === this.props.currentMinuteItem?.id &&
                                toast.pregenerated_minutes ===
                                    PREGENERATION_DOCUMENT_STATUS.AUTOMATED
                            ) {
                                if (toast.view_toast === true) {
                                    updatePregenerationAIMinutes(toast.id, toast.status, false)
                                }
                            }
                        }, 10000)
                    }
                })
            }
        } catch (error) {
            this.setState({ error: 'Error fetching data', loading: false })
        }
    }

    handleExpandCollapse = (isExpanded: boolean) =>
        this.props.actions.toggleExpandCollapseAll(isExpanded)

    scrollToLastListItem = () => {
        //Note we scroll to last added item (only once per new added item)
        if (!this.lastListItem || !this.list || this.isScrollingToLastItem) {
            return
        }
        this.isScrollingToLastItem = true

        const lastItem = ReactDOM.findDOMNode(this.lastListItem)

        if (!(lastItem instanceof HTMLElement)) {
            return
        }

        const list = ReactDOM.findDOMNode(this.list) as Element

        if (lastItem && list && lastItem.offsetTop) {
            list.scrollTop = lastItem.offsetTop
            setTimeout(() => (this.isScrollingToLastItem = false), 50)
        }
    }

    //This function return actuial node by id. It is required for the scroller.
    getListNodeById = (id) => {
        if (!this.allListNodes || !id) {
            return null
        }

        return this.allListNodes.get(id)
    }

    onResize = (evt) => {
        this.setState({ height: evt.height - headerHeight })
    }

    handleEditAction = (actionId) => {
        // this.props.actions.setGlobalFocusLockState(true)
        this.props.actions.editAction({ action: { actionId: actionId }, block: null })
    }

    handleDeleteAction = async (actionId) => {
        const minutesId = this.props.currentMinuteItem.id
        await deleteAction(minutesId, actionId, deleteAction.bind(null, minutesId, actionId))

        this.props.actions.removeAction(actionId)

        const minutesDetails = await getMinutesDetail(minutesId, null, true)
        this.props.actions.fetchMinuteItemFulfilled(minutesDetails)
    }

    getPregeneratedMinutes = async (status) => {
        const minutesId = this.props.currentMinuteItem.id
        try {
            const minutesDetails = await getMinutesDetail(minutesId, null, true)
            const pregeneratedMinutes =
                minutesDetails.minutesDocuments[minutesId].attributes.pregeneratedMinutes
            this.setState({ pregeneratedMinutes, loaded: true })
            const sectionsDetails = await getSections(minutesId)
            if (
                this.props.minutesSections?.length === 1 &&
                Object.keys(sectionsDetails.minutesSections).length === 1 &&
                (pregeneratedMinutes !== PREGENERATION_DOCUMENT_STATUS.PROGRESS ||
                    status === PREGENERATION_DOCUMENT_STATUS.CANCELED ||
                    status === PREGENERATION_DOCUMENT_STATUS.UNAPPROVED_ERROR ||
                    status === PREGENERATION_DOCUMENT_STATUS.FAILED)
            ) {
                this.props.actions.addSection()
            }
        } catch (error) {
            this.setState({ loaded: true })
        }
    }

    handleViewAllActions = (actionId) => {
        this.props.history.push(`/taker/${this.props.currentMinuteItem.id}/actions/${actionId}`, {
            actionId
        })
        this.props.actions.selectActionItem(actionId)
    }

    handleSectionReorder = (drag, hover) => {
        this.props.actions.changeSectionOrder(drag, hover)
    }

    createEditorItem = (
        list: Array<JSX.Element>,
        key: any,
        id: string,
        order: number,
        totalSections: number,
        isReadonlyDocument: boolean,
        count: number
    ) => {
        const ref = (c) => {
            this.lastListItem = c
            if (!c || !c.props) {
                return
            }
            this.allListNodes.set(c.props.sectionId, c)
        }

        const sectionActionItems = this.props.actionItems
            ? this.props.actionItems.filter((action) => {
                  return action.minutesSectionId === +id
              })
            : []

        //Sort section actions by sortOrder
        sectionActionItems.sort((a, b) => {
            if (a.sortOrder > b.sortOrder) return 1
            if (a.sortOrder < b.sortOrder) return -1

            return 0
        })

        const element = (
            <Draggable
                key={key}
                draggableId={key}
                index={count}
                direction="vertical"
                bounds="parent">
                {(provided, snapshot) => {
                    //for vertical movement while dragging
                    var transform = provided.draggableProps.style.transform
                    if (transform) {
                        var t = transform.split(',')[1]
                        var newStyle = {
                            ...provided.draggableProps.style,
                            transform: `translate(0px, ${t}`
                        }
                        provided.draggableProps.style = newStyle
                    }
                    return (
                        <div ref={provided.innerRef} {...provided.draggableProps}>
                            <EditorListItem
                                ref={ref}
                                key={key}
                                originalKey={key}
                                sectionId={id}
                                order={order}
                                totalCount={totalSections}
                                readonly={isReadonlyDocument}
                                onSectionReorder={this.handleSectionReorder}
                                actionItems={sectionActionItems}
                                onEditAction={this.handleEditAction}
                                onDeleteAction={this.handleDeleteAction}
                                onActionChecked={this.props.onActionChecked}
                                onViewAllActions={this.handleViewAllActions}
                                dragProps={provided.dragHandleProps}
                                snapshot={snapshot}
                            />
                        </div>
                    )
                }}
            </Draggable>
        )

        list.push(element)

        //If we have new item added its key is guid and so NaN
        //We then can scroll to it
        //We also store all scrolled to new item in a map
        //To scroll only once to those new items
        if (isNaN(key) && !this.scrolledNewItems.get(key)) {
            this.scrolledNewItems.set(key, true)
            setTimeout(() => this.scrollToLastListItem(), 50)
        }
    }

    getElementIdAndKey = (element) => {
        const id = element.id || element.tempId
        const key = element.tempId || element.id

        return {
            id,
            key
        }
    }
    getEditorItems = (isReadonlyDocument: boolean) => {
        const { currentMinuteItem, minutesSections } = this.props

        if (!currentMinuteItem || !minutesSections) {
            return null
        }

        let result: Array<JSX.Element> = []
        let count = 0
        for (var i in minutesSections) {
            if (minutesSections.hasOwnProperty(i)) {
                //Each list item has title and body. Body has section content.
                var element = minutesSections[i]
                if (element.sectionType !== 'minutes') {
                    continue
                }

                const { id, key } = this.getElementIdAndKey(element)

                //NOTE: Trick here is to use OLD tempId as key if available
                //That is to prevent items being destroyed as soon as new item is saved
                //We also pass original key attribute so underlying keyed controls could
                //use the same key before and after first save not destroying the original editor
                if (element.loaded) {
                    this.createEditorItem(
                        result,
                        key,
                        id,
                        element.order,
                        minutesSections.length,
                        isReadonlyDocument,
                        count
                    )
                } else {
                    //Note: loading all items at once is time consuming task
                    //because parsing server data takes time for each editor
                    //Parsing JSON into Draft JS is basically intrinsically slow
                    //So we load items one by one marking them loaded = false when
                    //we get them from server and then processing it here one by one
                    this.parseSectionData(element, minutesSections.length)
                    return result
                }
            }
            count++
        }
        return result
    }

    parseSectionData = (section, totalSectionsCount) => {
        //All this function does so far is marks items as loaded every X msec one by one
        //Then updates reducer so it has more "loaded" items and so it it would keep rendering
        //them in serial way
        setTimeout(() => {
            //Short breaks for smaller count of sections
            this.props.actions.parseSectionData(section)
        }, Math.min(100, totalSectionsCount * 5))
    }

    renderLoadingProgress = (minutesSections: Array<any>): JSX.Element | null => {
        if (minutesSections) {
            //We showing loading indicator
            if (minutesSections.find((c) => !c.loaded)) {
                return <LinearIndeterminate loading={true} />
            }
        }
        return null
    }
    onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return
        }

        this.props.actions.changeSectionOrder(result.source.index + 1, result.destination.index + 1)
    }
    onDragStart = () => {
        document.querySelectorAll('.ai-modal').forEach((it: any) => (it.style.display = 'none'))
    }

    updateGeneration = async (status: string) => {
        const minutesId = this.props.currentMinuteItem.id
        await updatePregenerationAIMinutes(minutesId, status)
        await this.getPregeneratedMinutes(status)
        if (status === 'acknowledged' || status === 'canceled') {
            this.setState({ pregeneratedMinutes: status })
        }
    }

    aiGenerateDialog = () => {
        return (
            <ClassNames>
                {({ css }) => (
                    <Dialog
                        open={this.state.pregeneratedMinutes === 'progress'}
                        className={css`
                            .MuiPaper-root.MuiDialog-paper {
                                max-width: 1000px;
                                width: 1000px;
                                max-height: 100%;
                                border-radius: 8px;
                            }
                        `}>
                        <div className={aiDialogContainer}>
                            <div className={aiDialogHeader}>
                                <AIGenerationIcon />
                                <HeaderText>{i18n.t('AI_BOOK_SUMMARY_GENERATE')}</HeaderText>
                            </div>
                            <Divider />
                            <ProgressMessage>
                                {i18n.t('AI_BOOK_SUMMARY_GENERATE_ALT')}
                                <br />
                                <br />
                                <LinearIndeterminate loading={true} />
                            </ProgressMessage>
                            <Divider />
                            <Box className={aiGenerateFooter}>
                                <Button
                                    className={customButton}
                                    style={{ color: '#455D82', border: '1px solid #455D82' }}
                                    onClick={() => this.setState({ navigatingOut: true })}
                                    data-analytics="MM-AI-PreGenerated-GoToListPage">
                                    {i18n.t('NOT_FOUND_LINK')}
                                </Button>
                                <Button
                                    className={customButton}
                                    style={{ backgroundColor: '#455D82', color: '#ffffff' }}
                                    onClick={() => this.updateGeneration('canceled')}
                                    data-analytics="MM-AI-PreGenerated-Cancel">
                                    {i18n.t('AI_BOOK_SUMMARY_GENERATE_CANCEL_BUTTON')}
                                </Button>
                            </Box>
                        </div>
                    </Dialog>
                )}
            </ClassNames>
        )
    }

    aiGenerationCompleted = () => {
        const userLanguage = getLocalStorageItem('userLanguage')
        return (
            <ClassNames>
                {({ css }) => (
                    <Dialog
                        open={
                            (this.state.pregeneratedMinutes || this.state.pregenerationStatus) ===
                            PREGENERATION_DOCUMENT_STATUS.AUTOMATED
                        }
                        className={css`
                            .MuiPaper-root.MuiDialog-paper {
                                max-width: 450px;
                                max-height: 100%;
                                border-radius: 8px;
                            }
                        `}>
                        <div className={aiDialogContainer}>
                            <div className={aiDialogHeader}>
                                <AIGenerationIcon />
                                <HeaderText>{i18n.t('MINUTES_READY')}</HeaderText>
                            </div>
                            <Divider />
                            <ProgressMessage>
                                {i18n.t('MINUTES_READY_ALT')}
                                <Link
                                    href={`https://help.diligent.com/diligenthelp/s/article/Data-processing-limitations-and-encryption-with-Diligent-AI?language=${userLanguage}`}
                                    target="blank">
                                    {i18n.t('LEARN_MORE')}
                                </Link>
                            </ProgressMessage>
                            <Divider />
                            <Box className={aiGenerateFooter}>
                                <Button
                                    className={customButton}
                                    style={{ backgroundColor: '#455D82', color: '#ffffff' }}
                                    onClick={() => this.updateGeneration('acknowledged')}
                                    data-analytics="MM-AI-PreGenerated-View">
                                    {i18n.t('VIEW')}
                                </Button>
                            </Box>
                        </div>
                    </Dialog>
                )}
            </ClassNames>
        )
    }

    showToastModel = (messageKey) => {
        const message = i18n.t(messageKey)
        return (
            <div
                className="toast-message"
                id="toast-message"
                style={{ borderLeft: '6px solid #AF292E', height: '68px' }}>
                <ToastErrorIcon />
                <p style={{ color: '#000000', width: '190px' }}>{message}</p>
                <button
                    id="close-error-btn"
                    onClick={() => {
                        this.updateGeneration('acknowledged')
                    }}>
                    <p>{i18n.t('TOAST_DISMISS_BUTTON')}</p>
                </button>
            </div>
        )
    }

    handleGenerationState = (state: any) => {
        switch (state) {
            case 'progress':
                return this.aiGenerateDialog()
            case 'automated':
                return this.aiGenerationCompleted()
            default:
                return null
        }
    }

    handlePreGenerationToast = (toast: any, documentStatus: any) => {
        switch (toast?.status) {
            case 'automated':
                return this.showPregenToast(toast)
            case 'unapproved_error':
                return this.showPregenToast(toast)
            case 'partial_success':
                return documentStatus === DOCUMENT_STATUS.DRAFT ? this.showPregenToast(toast) : null
            case 'failed':
                return documentStatus === DOCUMENT_STATUS.DRAFT ? this.showPregenToast(toast) : null
            default:
                return null
        }
    }

    showPregenToast = (toast: any) => {
        const message = i18n.t(toast.statusMessage)
        return toast?.author_id === toast.current_user_id &&
            toast &&
            (toast.view_toast === true ||
                (toast.id === this.props.currentMinuteItem?.id &&
                    (toast.status === PREGENERATION_DOCUMENT_STATUS.UNAPPROVED_ERROR ||
                        toast.status === PREGENERATION_DOCUMENT_STATUS.FAILED ||
                        toast.status === PREGENERATION_DOCUMENT_STATUS.PARTIAL_SUCCESS) &&
                    toast.view_toast === false)) ? (
            <div className={`custom-toast ${toast.status}`}>
                <div className="custom-toast-header">
                    <span className="custom-toast-icon">
                        {toast.status === PREGENERATION_DOCUMENT_STATUS.UNAPPROVED_ERROR ? (
                            <ToastErrorIcon />
                        ) : toast.status === PREGENERATION_DOCUMENT_STATUS.FAILED ? (
                            <ToastErrorIcon />
                        ) : toast.status === PREGENERATION_DOCUMENT_STATUS.PARTIAL_SUCCESS ? (
                            <ToastErrorIcon />
                        ) : (
                            <SuccessIcon />
                        )}
                    </span>
                    <div style={{ flexDirection: 'column' }}>
                        <div className={`custom-toast-title ${toast.status}`}>
                            <TitleLink
                                href={`/taker/${toast.id}`}
                                target="blank"
                                status={toast.status}>
                                {toast.MinutesTitle}
                            </TitleLink>
                        </div>
                        <div className="custom-toast-message">{message}</div>
                    </div>
                </div>
                <hr className="custom-toast-border" />
                <div className="custom-toast-buttons">
                    <button
                        className="dismiss"
                        onClick={() => {
                            if (
                                toast.id === this.props.currentMinuteItem?.id &&
                                (toast.status === PREGENERATION_DOCUMENT_STATUS.UNAPPROVED_ERROR ||
                                    toast.status === PREGENERATION_DOCUMENT_STATUS.FAILED ||
                                    toast.status === PREGENERATION_DOCUMENT_STATUS.PARTIAL_SUCCESS)
                            ) {
                                updatePregenerationAIMinutes(
                                    toast.id,
                                    PREGENERATION_DOCUMENT_STATUS.ACKNOWLEDGED,
                                    false
                                )
                            }
                            this.removeToast(toast.id)
                        }}>
                        {i18n.t('TOAST_DISMISS_BUTTON')}
                    </button>
                    {toast.id !== this.props.currentMinuteItem?.id && (
                        <button
                            className="action"
                            onClick={() => {
                                if (
                                    toast.id === this.props.currentMinuteItem?.id &&
                                    (toast.status ===
                                        PREGENERATION_DOCUMENT_STATUS.UNAPPROVED_ERROR ||
                                        toast.status === PREGENERATION_DOCUMENT_STATUS.FAILED ||
                                        toast.status ===
                                            PREGENERATION_DOCUMENT_STATUS.PARTIAL_SUCCESS)
                                ) {
                                    this.updateGeneration(
                                        PREGENERATION_DOCUMENT_STATUS.ACKNOWLEDGED
                                    )
                                }
                                applicationRouter.navigateTo(`/taker/${toast.id}`)
                            }}>
                            <Link
                                style={{ textDecoration: 'none' }}
                                href={`/taker/${toast.id}`}
                                target="blank">
                                {i18n.t('VIEW')}
                            </Link>
                        </button>
                    )}
                </div>
            </div>
        ) : null
    }

    render() {
        const {
            minutesSections,
            currentMinuteItem,
            currentSelectedTab,
            isEditingAction
        } = this.props

        let progress: JSX.Element | null = this.renderLoadingProgress(minutesSections)

        const preogressHeightCorrection = progress ? progressContainerSize : 0
        const rootHeight =
            this.state.height - preogressHeightCorrection - toolbarSize - newToolbarSize

        const documentStatus = pathOr(
            '',
            ['attributes', 'status'],
            currentMinuteItem
        ) as DocumentStatusKey

        const isReadonlyDocument =
            documentStatus === DOCUMENT_STATUS.FINAL_DRAFT ||
            documentStatus === DOCUMENT_STATUS.APPROVED
        const allItems = this.state.loaded && this.getEditorItems(isReadonlyDocument)
        const isMinutesLoading = () =>
            minutesSections ? minutesSections.find((c) => !c.loaded) : false

        // Required to remmount Action Dialog every time we use it in order to
        // reference the correct Action Item
        const actionItemDialog = isEditingAction ? <ActionItemEditDialog /> : null
        const { pregeneratedMinutes, pregenerationStatus } = this.state
        const aiGenerateBookSummary = this.handleGenerationState(
            pregeneratedMinutes || pregenerationStatus
        )
        const handletakerScreenNotification = (toast: any, currentMinuteItemId: any) => {
            if (toast?.id === currentMinuteItemId) {
                if (toast.status !== PREGENERATION_DOCUMENT_STATUS.AUTOMATED) {
                    return this.handlePreGenerationToast(toast, documentStatus)
                }
            } else if (toast?.id !== this.props?.currentMinuteItem?.id) {
                return this.handlePreGenerationToast(toast, documentStatus)
            }
            return null
        }

        if (this.state.navigatingOut) {
            return (
                <Redirect
                    to={{
                        pathname: '/',
                        state: {}
                    }}
                />
            )
        }
        return (
            <StyleWrapper height={rootHeight}>
                <Toolbar disabled={isMinutesLoading()}>
                    {(toolbarProps) => (
                        <DocumentToolbar
                            onStatusChange={this.props.onStatusChange}
                            isDocumentReadOnly={isReadonlyDocument}
                            {...toolbarProps}
                        />
                    )}
                </Toolbar>
                <EditorToolbar
                    currentMinuteItem={currentMinuteItem}
                    onStatusChange={this.props.onStatusChange}
                />
                {progress}
                {currentSelectedTab === EDITOR_TABS.ATTENDEES && (
                    <Attendees readonly={isReadonlyDocument} />
                )}

                {currentSelectedTab === EDITOR_TABS.EDITOR && (
                    <AutoScroller items={allItems} getListNodeById={this.getListNodeById}>
                        <RootRef rootRef={(c) => (this.list = c)}>
                            <List id="list-scroll">
                                {aiGenerateBookSummary}
                                <ListWrapper>
                                    <ListSubheader className={listSubheader}>
                                        {documentStatus === DOCUMENT_STATUS.DRAFT && (
                                            <Box className={ToolbarBoxCss}>
                                                <Box
                                                    className={ToolbarCss}
                                                    role="toolbar"
                                                    aria-label="Editor Toolbar">
                                                    <AddSectionButton
                                                        showLabels={true}
                                                        onClick={this.props.actions.addSection}
                                                        aria-label={i18n.t('ADD_SECTION_ITEM')}
                                                    />
                                                    <AddActionButton
                                                        showLabels={true}
                                                        onClick={this.props.actions.addAction}
                                                        aria-label={i18n.t('ADD_ACTION_ITEM')}
                                                    />
                                                </Box>
                                                <Box>
                                                    <Toolbar>
                                                        {(toolbarProps) =>
                                                            minutesSections?.length > 1 ? (
                                                                <UtilityExpandCollapse
                                                                    toggleExpandCollapseAll={
                                                                        toolbarProps.actions
                                                                            .toggleExpandCollapseAll
                                                                    }
                                                                />
                                                            ) : (
                                                                ''
                                                            )
                                                        }
                                                    </Toolbar>
                                                </Box>
                                            </Box>
                                        )}
                                    </ListSubheader>
                                    <DragDropContext
                                        onDragEnd={this.onDragEnd}
                                        onDragStart={this.onDragStart}>
                                        <Droppable droppableId="droppable">
                                            {(provided) => (
                                                <div
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}>
                                                    {allItems}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                    <Footer
                                        sectionCount={allItems ? allItems.length : 0}
                                        onSectionReorder={this.handleSectionReorder}
                                    />
                                </ListWrapper>
                            </List>
                        </RootRef>
                    </AutoScroller>
                )}
                {currentSelectedTab === EDITOR_TABS.ACTIONS && (
                    <MinutesActions setIsLoading={this.props.setIsLoading} />
                )}
                {actionItemDialog}
                <ToastContainer>
                    {this.state.toasts.map((toast: any) =>
                        handletakerScreenNotification(toast, this.props?.currentMinuteItem?.id)
                    )}
                </ToastContainer>
                <DomHelper onResize={this.onResize} />
            </StyleWrapper>
        )
    }
}
//#endregion

//#region Export / Redux Bindings

const mapStateToProps = (state, _) => {
    return {
        currentMinuteItem: selectors.currentMinuteItem(state.minuteTakerReducer),
        minutesSections: selectors.minutesSections(state.minuteTakerReducer),
        selectedTabIndex: selectors.selectedTabIndex(state.minuteTakerReducer),
        actionItems: selectors.minutesActions(state.minuteTakerReducer),
        isEditingAction: selectors.isEditingAction(state.minuteTakerReducer),
        currentSelectedTab: selectors.currentSelectedTab(state.minuteTakerReducer)
    }
}

const mapDispatchToProps = (dispatch) => {
    const {
        parseSectionData,
        addAction,
        addSection,
        loadSections,
        editAction,
        removeAction,
        toggleExpandCollapseAll,
        selectActionItem,
        changeSectionOrder,
        loadActionsFulfilled,
        fetchMinuteItemFulfilled
    } = {
        ...actions,
        ...actionEditorReducer
    }

    return {
        actions: bindActionCreators(
            {
                parseSectionData,
                addAction,
                addSection,
                loadSections,
                editAction,
                removeAction,
                toggleExpandCollapseAll,
                selectActionItem,
                changeSectionOrder,
                loadActionsFulfilled,
                fetchMinuteItemFulfilled
            },
            dispatch
        )
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MinuteEditorListView))
//#endregion
